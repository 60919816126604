// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-info-tsx": () => import("/opt/build/repo/src/templates/info.tsx" /* webpackChunkName: "component---src-templates-info-tsx" */),
  "component---src-templates-statuscodes-tsx": () => import("/opt/build/repo/src/templates/statuscodes.tsx" /* webpackChunkName: "component---src-templates-statuscodes-tsx" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

